import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UsersService } from 'gen';
import {
  filter,
  interval,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AuthenticationService } from '@shared/services';

type NotificationCounterState = {
  nbRead: number;
  nbTotal: number;
  nbUnread: number;
};

const INITAIL_STATE: NotificationCounterState = {
  nbRead: 0,
  nbTotal: 0,
  nbUnread: 0,
};

@Injectable({
  providedIn: 'root',
})
export class NotificationCounterService {
  private readonly usersService = inject(UsersService);
  private readonly authenticationService = inject(AuthenticationService);

  private readonly notificationCounter = signal(INITAIL_STATE);

  readonly notificationUnread = computed(
    () => this.notificationCounter().nbUnread
  );

  readonly notificationTotal = computed(
    () => this.notificationCounter().nbTotal
  );

  public readonly isAuthenticated$ =
    this.authenticationService.isAuthenticated$;

  constructor() {
    interval(120_000) // 120_000s => every 2min
      .pipe(
        startWith(0), //  fetch  the notifications without waiting the first 2min
        withLatestFrom(this.isAuthenticated$),
        filter(([, userIsAuthenticated]) => userIsAuthenticated),
        switchMap(() =>
          this.usersService.usersMeNotificationsCountersRetrieve()
        ),
        tap(({ nb_read, nb_unread, nb_total }) => {
          this.notificationCounter.set({
            nbRead: nb_read ?? 0,
            nbTotal: nb_total ?? 0,
            nbUnread: nb_unread ?? 0,
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  read(): void {
    this.notificationCounter.update(state => ({
      ...state,
      nbUnread: state.nbUnread > 0 ? state.nbUnread - 1 : 0,
      nbRead: state.nbRead + 1,
    }));
  }
}

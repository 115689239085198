import { Injectable, OnDestroy, TemplateRef } from '@angular/core';

interface ToastOption {
  className: string;
  delay: number;
  header: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {
  public toasts: any[] = [];

  private show(
    textOrTpl: string | TemplateRef<unknown>,
    options: ToastOption
  ): void {
    this.toasts.push({ textOrTpl, ...options });
  }

  public remove(toast: string) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear(): void {
    this.toasts.splice(0, this.toasts.length);
  }

  showStandard(message: string, headerTitle = 'Info'): void {
    this.show(message, {
      className: 'bg-primary text-light',
      delay: 10000,
      header: headerTitle,
    });
  }

  showSuccess(message: string, headerTitle = 'Succès'): void {
    this.show(message, {
      className: 'bg-success text-light',
      delay: 10000,
      header: headerTitle,
    });
  }

  showDanger(message: string | any, headerTitle = 'Une erreur est survenue') {
    this.show(message, {
      className: 'bg-danger text-light',
      delay: 15000,
      header: headerTitle,
    });
  }

  ngOnDestroy(): void {
    this.clear();
  }
}
